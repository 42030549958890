
      import Template7 from 'template7';
      const Template7Helpers = Template7.helpers;
  
      
  
      import * as cons from '../js/constants';
import * as fn from '../js/functions';
export default {
  on: {
    pageInit: function (event, page) {
    },
    pageAfterIn: function (event, page) {
      $$('#form-request-detail').on('submit', function (e) {
        e.preventDefault();
        app.preloader.show();
        var request_id = event.detail.route.params.requestId;
        axios.post(cons.URL_REQUESTS_POST, {
          user_id: auth.id,
          request_id: request_id
        }).then(function (response) {
          var url = cons.URL + '/' + cons.URL_APP_FORM + '/' + response.data.token;
          if (window.browser) {
            window.open(url, '_blank');
          } else {
            cordova.InAppBrowser.open(url, '_system', 'hidenavigationbuttons=yes,location=yes,zoom=no,hardwareback=no,fullscreen=yes,hideurlbar=yes');
          }
          app.preloader.hide();
          app.view.main.router.navigate('/', { clearPreviousHistory: true });
        }).catch(function (error) {
          fn.errorResponseHandler(error);
        });
      });
    }
  },
  id: '4b6267405d',
  render() {
    return function (ctx_1, data_1, root) {
      function isArray(arr) {
        return Array.isArray(arr);
      }
      function isFunction(func) {
        return typeof func === 'function';
      }
      function c(val, ctx) {
        if (typeof val !== 'undefined' && val !== null) {
          if (isFunction(val)) {
            return val.call(ctx);
          } else
            return val;
        } else
          return '';
      }
      root = root || ctx_1 || {};
      var r = '';
      r += '\r\n    <div class="page solicitudes-detalle" data-name="solicitudes-detalle">\r\n        <!-- Top Navbar -->\r\n        <div class="navbar">\r\n            <div class="navbar-bg"></div>\r\n            <div class="navbar-inner">\r\n                <div class="left">\r\n                    <a href="#" class="link back">\r\n                        <i class="icon-chevron-left-big"></i>\r\n                    </a>\r\n                </div>\r\n                <div class="title logo"><img src="static/img/logo.png" alt="" srcset=""></div>\r\n            </div>\r\n        </div>\r\n        <!-- Scrollable page content-->\r\n        <div class="page-content">\r\n            <form method="post" id="form-request-detail">\r\n                <input type="hidden" name="request_id" value="';
      r += c(ctx_1.request.id, ctx_1);
      r += '">\r\n\r\n                <div class="block title-page-detalle">\r\n                    <h2 class="mt-3">';
      r += c(ctx_1.request.name, ctx_1);
      r += '</h2>\r\n                </div>\r\n\r\n                <div class="block">\r\n                    <h3>Requisitos</h3>\r\n                </div>\r\n\r\n                <div class="block mb-5">\r\n                    ';
      r += c(ctx_1.request.requirements, ctx_1);
      r += '\r\n                </div>\r\n                ';
      r += Template7Helpers.unless.call(ctx_1, ctx_1.canrequest, {
        hash: {},
        data: data_1 || {},
        fn: function (ctx_2, data_2) {
          var r = '';
          r += '\r\n                    <div class="block">\r\n                        <div class="row d-flex-center">\r\n                            <div class="xsmall-100 d-flex-center">\r\n                                <h3 style="width: 100%; text-align: center; font-weight: bold;">Ud. debe estar al día con el gremio para poder realizar esta solicitud.</h3>                \r\n                            </div>\r\n                        </div>\r\n                    </div>\r\n                ';
          return r;
        },
        inverse: function (ctx_2, data_2) {
          var r = '';
          r += '\r\n                   \r\n                    <div class="block">\r\n                        <div class="row d-flex-center">\r\n                            <div class="xsmall-100">\r\n                                <!-- <input type="text" id="price" name="price" value="';
          r += c(ctx_2.request.price, ctx_2);
          r += '"> -->\r\n                                <button type="submit" class="button button-fill btn-primary">\r\n                                    REALIZAR / CONTINUAR SOLICITUD &nbsp;<i class="f7-icons">chevron_right</i></a>\r\n                                </button>\r\n                            </div>\r\n                        </div>\r\n                    </div>\r\n                    <div class="block">\r\n                        \r\n                    </div>\r\n\r\n                ';
          return r;
        },
        root: root,
        parents: [ctx_1]
      });
      r += '\r\n\r\n            </form>\r\n        </div>\r\n    </div>\r\n';
      return r;
    }(this);
  },
  styleScoped: false
};
    