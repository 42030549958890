export const URL                  = 'https://cmd.org.do/app/admin';
export const URL_API              = 'https://cmd.org.do/app/admin/api/';

// export const URL                  = 'https://cmd.org.do/app/admin_test';
// export const URL_API              = 'https://cmd.org.do/app/admin_test/api/';

// export const URL                  = 'http://127.0.0.1:8000';
// export const URL_API              = 'http://127.0.0.1:8000/api/';



export const URL_CHECK            = 'auth/check';
export const URL_SIGNUP           = 'auth/signup';
export const URL_LOGIN            = 'auth/login';
export const URL_LOGOUT           = 'auth/logout';
export const URL_REGISTER_DEVICE  = 'auth/register-device';
export const URL_PASSWORD_REQUEST = 'password/create';
// export const URL_REQUESTS_POST    = 'account/requests/post/';
export const URL_REQUESTS_POST    = 'request';
export const URL_APP_FORM         = 'app/form';
export const URL_SUPPORT          = 'account/support';
export const URL_PROFILE          = 'account/profile';
export const URL_ORDER            = 'account/order';
export const URL_PAYMENT          = 'payment/';
export const URL_REFRESH          = 'account/refresh';

export const FIREBASE             = "https://cmd-app-74998.firebaseio.com";
export const SENDER_ID            = "1068008108300";
export const GCM_SANDBOX          = false;  // false para produccion
export const VERSION_APP          = '1.5.1';