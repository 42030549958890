
      import Template7 from 'template7';
      const Template7Helpers = Template7.helpers;
  
      
  
      export default {
  on: {
    pageAfterIn: function (event, page) {
    },
    pageInit: function (event, page) {
    }
  },
  id: '72bc1e2cc8',
  render() {
    return function (ctx_1, data_1, root) {
      function isArray(arr) {
        return Array.isArray(arr);
      }
      function isFunction(func) {
        return typeof func === 'function';
      }
      function c(val, ctx) {
        if (typeof val !== 'undefined' && val !== null) {
          if (isFunction(val)) {
            return val.call(ctx);
          } else
            return val;
        } else
          return '';
      }
      root = root || ctx_1 || {};
      var r = '';
      r += '\r\n    <div class="page solicitudes-detalle" data-name="solicitudes-detalle">\r\n        <!-- Top Navbar -->\r\n        <div class="navbar">\r\n            <div class="navbar-bg"></div>\r\n            <div class="navbar-inner">\r\n                <div class="left">\r\n                    <a href="#" class="link back">\r\n                        <i class="icon-chevron-left-big"></i>\r\n                    </a>\r\n                </div>\r\n                <div class="title logo"><img src="static/img/logo.png" alt="" srcset=""></div>\r\n            </div>\r\n        </div>\r\n        <!-- Scrollable page content-->\r\n        <div class="page-content">\r\n            <div class="block block-strong title-page">\r\n                <p><img src="static/img/icon-historico-circle.svg" alt=""> Cuota #';
      r += c(ctx_1.cuota.cuota_id, ctx_1);
      r += '</p>\r\n            </div>\r\n\r\n            <div class="block">\r\n                ';
      r += Template7Helpers.each.call(ctx_1, ctx_1.cuota.details, {
        hash: {},
        data: data_1 || {},
        fn: function (ctx_2, data_2) {
          var r = '';
          r += '\r\n                    <div class="card data-table data-table-collapsible data-table-init">\r\n                        <div class="card-header">\r\n                            <div class="data-table-title">Orden #';
          r += c(ctx_2.order.id, ctx_2);
          r += '</div>\r\n                            <div class="data-table-actions">\r\n                                <a class="link icon-only">\r\n                                    <i class="icon f7-icons if-not-md">sort</i>\r\n                                    <i class="icon material-icons md-only">sort</i>\r\n                                </a>\r\n                                <a class="link icon-only">\r\n                                    <i class="icon f7-icons if-not-md">more_vertical_round</i>\r\n                                    <i class="icon material-icons md-only">more_vert</i>\r\n                                </a>\r\n                            </div>\r\n                        </div>\r\n                        <div class="card-content">\r\n                            <table>\r\n                                <thead>\r\n                                    <tr>\r\n                                        <th class="label-cell">Nro. Aprobación</th>\r\n                                        <th class="numeric-cell">Monto</th>\r\n                                        <th class="numeric-cell">Status</th>\r\n                                    </tr>\r\n                                </thead>\r\n                                <tbody>\r\n                                    <tr>\r\n                                        <td class="label-cell">';
          r += c(ctx_2.order.approval_code, ctx_2);
          r += '</td>\r\n                                        <td class="numeric-cell">';
          r += c(ctx_2.order.total, ctx_2);
          r += '</td>\r\n                                        <td class="numeric-cell">\r\n                                            ';
          r += Template7Helpers.js_if.call(ctx_2, 'this.order.status === \'complete\'', {
            hash: {},
            data: data_2 || {},
            fn: function (ctx_3, data_3) {
              var r = '';
              r += '\r\n                                                <span class="badge color-green">Completado</span>\r\n                                            ';
              return r;
            },
            inverse: function (ctx_3, data_3) {
              var r = '';
              r += '\r\n                                                <span class="badge color-red">Fallido</span>\r\n                                            ';
              return r;
            },
            root: root,
            parents: [ctx_1]
          });
          r += '\r\n                                        </td>\r\n                                    </tr>\r\n                                </tbody>\r\n                            </table>\r\n                        </div>\r\n                    </div>\r\n                ';
          return r;
        },
        inverse: function empty() {
          return '';
        },
        root: root,
        parents: [ctx_1]
      });
      r += '\r\n            </div>\r\n        </div>\r\n    </div>\r\n';
      return r;
    }(this);
  },
  styleScoped: false
};
    