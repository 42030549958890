import * as cons from './constants';

String.prototype.replaceAll = function (search, replacement) {
    var target = this;
    return target.split(search).join(replacement);
};

/**
 * Mostrar una notificacion
 * 
 * @param {Object} data Parametros
 */
export const notification = (data) => {
    if (data.icon === undefined) var icon = data.icon; else var icon = '<i class="icon-favicon"></i>';
    if (data.title === undefined) var title = data.title; else var title = app.name;

    var notificationClickToClose = app.notification.create({
        icon: icon,
        title: title,
        titleRightText: '',
        subtitle: data.subtitle,
        text: data.text,
        closeOnClick: true,
        closeTimeout: 4000,
    });
    notificationClickToClose.open();
}

/**
 * Mostrar un dialogo
 * 
 * @param {Object} data Parametros
 */
export const dialog = (data) => {
    var title = data.title === undefined ? app.name : data.title;
    var callback = data.callback === undefined ? null : data.callback;
    app.dialog.alert(data.text, title, callback);
}

/**
 * Error handling para axios
 * 
 * @param {Object} error 
 */
export const errorResponseHandler = (error) => {
    // check for errorHandle config
    if ( error.config.hasOwnProperty('errorHandle') && error.config.errorHandle === false ) {
        return Promise.reject(error);
    }
    
    if (error.response) {
        var data = error.response.data;
        dialog({
            'text': data.message.body,
            'title': data.message.title
        });
        
    } else if (error.request) {
        // console.log(error.request);
    } else {
        console.log('Error', error.message);
    }
    // console.log(error.config);

    app.preloader.hide();
}

/**
 * Logout
 */
export const logout = () => {
    app.preloader.show();
    
    axios.get(cons.URL_LOGOUT)
    .then(function (response) {
        localStorage.clear();
        auth = null;
        token = null;
        
        app.preloader.hide();
        app.loginScreen.open('#my-login-screen');
        app.panel.close('.panel-init')
    })
    .catch(function (error) {
        localStorage.clear();
        auth = null;
        token = null;

        errorResponseHandler(error);
    });
}

/**
 * Cargar info del usuario
 */
export const infoUser = () => {
    $$('.lbl-fullname').html(auth.name + ' ' + auth.lastname);
    $$('.lbl-lastupdate').html(auth.updated_at_formated);
    $$('.lbl-balance').html(auth.dues.balance_formated);
    $$('.lbl-idcardnet').html(auth.idcardnet);
}

/**
 * Formatear numero a decimal
 * @param {String} numero Numero a formatear
 */
export const unMasked = (numero) => {
    var str = numero;
    str = str.replaceAll("RD$","");
    str = str.replaceAll(",","");
    // var newstr = str.replace("RD$", "", "gi");
    // newstr = newstr.replace(",", "");
    
    return str;
}

//clearInterval(refreshInterval);
export const refreshAppData = () => {
    axios.get(cons.URL_REFRESH)
    .then(function (response) {
        // console.log(response);
        
        var user  = response.data.user;

        if (auth.dues.balance_formated != user.dues.balance_formated) {
            clearInterval(refreshInterval);
        }
        
        localStorage.setItem("auth", JSON.stringify(user));
        auth = user;
        
        infoUser();
    })
    .catch(function (error) {
        fn.errorResponseHandler(error);
    });
}

/**
 * Registrar dispositivo
 */
export const registerDevice = () => {
}

export const cipher = salt => {
    const textToChars = text => text.split('').map(c => c.charCodeAt(0));
    const byteHex = n => ("0" + Number(n).toString(16)).substr(-2);
    const applySaltToChar = code => textToChars(salt).reduce((a,b) => a ^ b, code);

    return text => text.split('')
        .map(textToChars)
        .map(applySaltToChar)
        .map(byteHex)
        .join('');
}

export const decipher = salt => {
    const textToChars = text => text.split('').map(c => c.charCodeAt(0));
    const applySaltToChar = code => textToChars(salt).reduce((a,b) => a ^ b, code);
    return encoded => encoded.match(/.{1,2}/g)
        .map(hex => parseInt(hex, 16))
        .map(applySaltToChar)
        .map(charCode => String.fromCharCode(charCode))
        .join('');
}

export const salt = () => {
    return "elcolegiomedicodominicano";
}