
      import Template7 from 'template7';
      const Template7Helpers = Template7.helpers;
  
      
  
      export default {
  on: {
    pageAfterIn: function (event, page) {
    },
    pageInit: function (event, page) {
      var searchbar = app.searchbar.create({
        el: '.searchbar',
        searchContainer: '.list',
        searchIn: '.item-title',
        backdrop: false,
        on: {
          search(sb, query, previousQuery) {
          }
        }
      });
    }
  },
  id: 'e8f481bb6c',
  render() {
    return function (ctx_1, data_1, root) {
      function isArray(arr) {
        return Array.isArray(arr);
      }
      function isFunction(func) {
        return typeof func === 'function';
      }
      function c(val, ctx) {
        if (typeof val !== 'undefined' && val !== null) {
          if (isFunction(val)) {
            return val.call(ctx);
          } else
            return val;
        } else
          return '';
      }
      root = root || ctx_1 || {};
      var r = '';
      r += '\n    <div class="page solicitudes" data-name="solicitudes">\n        <!-- Top Navbar -->\n        <div class="navbar">\n            <div class="navbar-bg"></div>\n            <div class="navbar-inner">\n                <div class="left">\n                    <a href="#" class="link back">\n                        <i class="icon-chevron-left-big"></i>\n                    </a>\n                </div>\n                <div class="title logo"><img src="static/img/logo.png" alt="" srcset=""></div>\n            </div>\n        </div>\n        <!-- Scrollable page content-->\n        <div class="page-content">\n            <div class="block block-strong title-page">\n                <p><img src="static/img/icon-solicitud-circle.svg" alt=""> Realizar solicitud</p>\n            </div>\n\n            <div class="block">\n                <form class="searchbar">\n                    <div class="searchbar-inner">\n                        <div class="searchbar-input-wrap">\n                            <input type="search" placeholder="Buscar">\n                            <i class="searchbar-icon"></i>\n                            <span class="input-clear-button"></span>\n                        </div>\n                        <span class="searchbar-disable-button">Cancelar</span>\n                    </div>\n                </form>\n            </div>\n\n            <div class="block">\n                <!-- Searchbar backdrop -->\n                <!-- <div class="searchbar-backdrop"></div> -->\n                <!-- search target list -->\n                <div class="list searchbar-found list-requests">\n                    <ul>\n                        ';
      r += Template7Helpers.each.call(ctx_1, ctx_1.auth.requests, {
        hash: {},
        data: data_1 || {},
        fn: function (ctx_2, data_2) {
          var r = '';
          r += '\n                            <li class="item-content">\n                                <a href="/solicitudes-detalle/';
          r += c(ctx_2.id, ctx_2);
          r += '" class="link">\n                                    <div class="item-inner">\n                                        <div class="item-title"><h3>';
          r += c(ctx_2.name, ctx_2);
          r += '</h3></div>\n                                    </div>\n\n                                    <i class="f7-icons">chevron_right</i></a>\n                                </a>\n                            </li>\n                        ';
          return r;
        },
        inverse: function empty() {
          return '';
        },
        root: root,
        parents: [ctx_1]
      });
      r += '\n                    </ul>\n                </div>\n                <!-- Nothing found message -->\n                <div class="block searchbar-not-found">\n                    <div class="block-inner">No se encontraron solicitudes</div>\n                </div>\n            </div>\n        </div>\n    </div>\n';
      return r;
    }(this);
  },
  styleScoped: false
};
    