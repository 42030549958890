import * as cons from './constants';
import * as fn from './functions';

import HomePage from '../pages/home.f7.html';
import PagoPage from '../pages/pago.f7.html';
import HistoricoPage from '../pages/historico.f7.html';
import HistoricoDetallePage from '../pages/historico-detalle.f7.html';
import SolicitudesPage from '../pages/solicitudes.f7.html';
import SolicitudesDetallePage from '../pages/solicitudes-detalle.f7.html';
import SoportePage from '../pages/soporte.f7.html';
import ProfilePage from '../pages/profile.f7.html';
import { log } from 'util';


var routes = [
    {
        path: '/',
        component: HomePage,
        ignoreChache: true,
        reload: true,
        on: {
            pageAfterIn: function (event, page) {
                var app = page.app;
            },
            pageInit: function (event, page) {
                var app = page.app;
                if (!auth) app.loginScreen.open('#my-login-screen');
                else {
                    fn.infoUser();
                }
                
                //  Popup registrar usuario (chequear)
                $$('#form-register-check').off('submit');
                $$('#form-register-check').on('submit', function(e) {
                    e.preventDefault();
                    app.preloader.show();

                    var formData = app.form.convertToData('#form-register-check');
                    axios.post(cons.URL_CHECK, formData)
                    .then(function (response) {
                        if (response.data.user){

                            var data = response.data.user;
                            $$("#registro_miembro").text(data.idcardnet);
                            $$('#form-register-signup [name="idcardnet"]').val(data.idcardnet);
                            $$('#form-register-signup [name="doc_id"]').val(data.doc_id);
                            $$('#form-register-signup [name="name"]').val(data.name);
                            $$('#form-register-signup [name="lastname"]').val(data.lastname);
                            $$('#form-register-signup [name="email"]').val(data.email);

                            $$('.register-step1').addClass('d-none');
                            $$('.register-step2').removeClass('d-none');
                        }else{
                            fn.dialog({
                                'text': "Error procesando su solicitud",
                            });
                        }    
                        app.preloader.hide();
                    })
                    .catch(function (error) {
                        fn.errorResponseHandler(error);
                    });
                });
                
                //  Popup registrar usuario completar
                $$('#form-register-signup').off('submit');
                $$('#form-register-signup').on('submit', function(e) {
                    e.preventDefault();
                    app.preloader.show();

                    var formData = app.form.convertToData('#form-register-signup');
                    axios.post(cons.URL_SIGNUP, formData)
                    .then(function (response) {
                        
                        if (response.data.data.user){
                            var data    = response.data.user;
                            var message = response.data.message;
                            
                            app.preloader.hide();
                            app.popup.close();

                            $$('.register-step1').removeClass('d-none');
                            $$('.register-step2').addClass('d-none');
                            $$('#form-register-check')[0].reset();
                            $$('#form-register-signup')[0].reset();

                            fn.dialog({
                                'text': message.body,
                                'title': message.title
                            });
                        }else{
                            fn.dialog({
                                'text': "Error procesando su solicitud",
                            });
                        }
                        app.preloader.hide();
                    })
                    .catch(function (error) {
                        fn.errorResponseHandler(error);
                    });
                });
                
                //  Popup registrar usuario completar
                $$('#form-login').off('submit');
                $$('#form-login').on('submit', function(e) {
                    e.preventDefault();
                    app.preloader.show();

                    var formData = app.form.convertToData('#form-login');
                    axios.post(cons.URL_LOGIN, formData)
                    .then(function (response) {
                        if (response.data.user){
                            var user  = response.data.user;
                            var token = response.data.token_type + ' ' + response.data.access_token;
                            localStorage.clear();
                            
                            /**
                             * 16/09/2020
                             */
                            var myCipher = fn.cipher(fn.salt());
                            var data = {
                              a: myCipher(formData.idcardnet),
                              b: myCipher(formData.password),
                            };
                            localStorage.setItem("al", JSON.stringify(data));

                            /**
                             * fin
                             */
                            


                            localStorage.setItem("auth", JSON.stringify(user));
                            localStorage.setItem("token", token);
                            auth = user;
                            token = token;

                            axios.defaults.headers.common['Authorization'] = token;
                            
                            app.loginScreen.close('#my-login-screen');
                            $$('#form-login')[0].reset();

                            fn.infoUser();
                        }else{
                            fn.dialog({
                                'text': "Error procesando su solicitud",
                            });
                        }
                        app.preloader.hide();
                    })
                    .catch(function (error) {
                        console.log(error);
                        fn.errorResponseHandler(error);
                    });
                });
                
                //  Popup registrar usuario (chequear)
                $$('#form-reset-request').off('submit');
                $$('#form-reset-request').on('submit', function(e) {
                    e.preventDefault();
                    app.preloader.show();

                    var formData = app.form.convertToData('#form-reset-request');
                    axios.post(cons.URL_PASSWORD_REQUEST, formData)
                    .then(function (response) {
                        if (response.data.message){
                            fn.dialog({
                                'text': response.data.message.body,
                                'title': response.data.message.title,
                                'callback': function(){
                                    $$("#form-reset-request")[0].reset();
                                    app.popup.close('#popup-reset-request');
                                }
                            });
                        }else{
                            fn.dialog({
                                'text': "Error procesando su solicitud",
                            });
                        }
                        
                        app.preloader.hide();
                    })
                    .catch(function (error) {
                        fn.errorResponseHandler(error);
                    });
                });
            },
        }
    },
    
    {
        path: '/pago/',
        async: function (routeTo, routeFrom, resolve, reject) {
            resolve({
                component: PagoPage,
            }, {
                context: {
                    auth: auth,
                }
            });
        },
    },
    
    {
        path: '/historico/',
        async: function (routeTo, routeFrom, resolve, reject) {
            resolve({
                component: HistoricoPage,
            }, {
                context: {
                    auth: auth,
                }
            });
        },
    },
    
    {
        path: '/historico-detalle/:cuota_id',
        async: function (routeTo, routeFrom, resolve, reject) {
            var id       = routeTo.params.cuota_id;
            var cuotas = auth.dues.items.partial;
            var cuota  = cuotas.find(cuota => cuota.cuota_id == id);
           
            resolve({
                component: HistoricoDetallePage,
            }, {
                context: {
                    auth : auth,
                    cuota: cuota,
                }
            });
        },
    },
    
    {
        path: '/solicitudes/',
        async: function (routeTo, routeFrom, resolve, reject) {
            resolve({
                component: SolicitudesPage,
            }, {
                context: {
                    auth: auth,
                }
            });
        },
    },
    
    {
        path: '/solicitudes-detalle/:requestId',
        async: function (routeTo, routeFrom, resolve, reject) {
            var id       = routeTo.params.requestId;
            var requests = auth.requests;
            var request  = requests.find(request => request.id == id);
            resolve({
                component: SolicitudesDetallePage,
            }, {
                context: {
                    auth   : auth,
                    request: request,
                    balance: auth.dues.balance,
                    canrequest: (request.nodebt == 0 || auth.dues.balance == 0) ? true : false,
                    // canrequest: true,
                }
            });
        },
    },
    
    {
        path: '/soporte/',
        async: function (routeTo, routeFrom, resolve, reject) {
            resolve({
                component: SoportePage,
            }, {
                context: {
                    auth: auth,
                }
            });
        },
    },
    
    {
        path: '/profile/',
        async: function (routeTo, routeFrom, resolve, reject) {
            resolve({
                component: ProfilePage,
            }, {
                context: {
                    auth: auth,
                }
            });
        },
    },
];

export default routes;