import $$ from 'dom7';
import Framework7 from 'framework7/framework7.esm.bundle.js';

// Import F7 Styles
import 'framework7/css/framework7.bundle.css';

// Import Icons and App Custom Styles
import '../css/icons.css';
import '../css/app.scss';

import jQuery from 'jquery';
// Import Cordova APIs
import cordovaApp from './cordova-app.js';
// Import Routes
import routes from './routes.js';

import { logout, registerDevice } from './functions';
import { URL_API } from './constants';
import * as cons from './constants';
import * as fn from './functions';


window.jQuery = jQuery;
window.$      = jQuery;

window.app = new Framework7({
    root : '#app',              // App root element
    id   : 'ado.org.cmd.app',   // App bundle ID
    name : 'CMD APP',           // App name
    theme: 'ios',               // Automatic theme detection
    iosTranslucentBars: false,
    iosTranslucentModals: false,
    clicks: {
        externalLinks: '.external',
    },
    touch:{
        activeState: false,
    },
    // App root data
    data: function () {
        return {
            user: {
                firstName: 'John',
                lastName: 'Doe',
            },
        };
    },
    // App root methods
    methods: {
        helloWorld: function () {
            app.dialog.alert('Hello World!');
        },
    },
    // App routes
    routes: routes,
    
    // Input settings
    input: {
        scrollIntoViewOnFocus: Framework7.device.cordova && !Framework7.device.electron,
        scrollIntoViewCentered: Framework7.device.cordova && !Framework7.device.electron,
    },
    // Cordova Statusbar settings
    statusbar: {
        iosBackgroundColor    : '#649F0F',
        androidBackgroundColor: '#386101',
        iosTextColor          : 'white',
        androidTextColor      : 'white',
    },
    on: {
        init: function () {
            var f7 = this;
            if (f7.device.cordova) {
                // Init cordova APIs (see cordova-app.js)
                cordovaApp.init(f7);
                //registerDevice();
            }
        },
    },
});

window.$$ = $$;

window.Inputmask = require('inputmask');

window.axios = require('axios');
window.axios.defaults.baseURL = URL_API;

var authStorage = JSON.parse(localStorage.getItem("auth"));
var tokenStorage = localStorage.getItem("token");

/**
 * 16/09/2020
 * 
 * auto login
 */

authStorage=false;

var autologin = JSON.parse(localStorage.getItem("al"));

if (autologin){
    setTimeout(function () {
        var myDecipher = fn.decipher(fn.salt())
        $('#form-login #idcardnet').val(myDecipher(autologin.a));
        $('#form-login #password').val(myDecipher(autologin.b));
        $('#login-button').trigger("click");
    }, 100);
}



/**
 * fin
 */

if (!authStorage) {
    window.auth = null;
    window.token = null;
} else {
    window.auth = authStorage;
    window.token = tokenStorage;
    window.axios.defaults.headers.common['Authorization'] = token;
}
window.refreshInterval = null;

// Show password
$$('.item-input-wrap .icon-show').off('click');
$$('.item-input-wrap .icon-show').on('click', function() {
    var $icon = $$(this);
    var $input = $$(this).parent().find('input');

    $icon.toggleClass('active');
    if ($icon.hasClass('active')) $input.attr('type', 'text');
    else $input.attr('type', 'password');
});

$$('.btn-logout').off('click');
$$('.btn-logout').on('click', function() {
    logout();
});



// $("a.link").on("contextmenu", futncion (e) {
//     e.preventDefault();
// });

$(document).ready(function() {
  $(document).bind("contextmenu", function(e) {
     //if(!$(e.target).is('img')){
        return false;
     //}
  });
});

